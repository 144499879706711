/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-03-13 10:54:47
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-03-16 19:42:37
 */
export default [
  // --------丽程分累计----------- //
  {
    // 丽程分累计项目-列表
    path: '/pmsone/cumulativelist',
    name: 'pmsone_cumulativelist',
    meta: {
      // 可选
      // permissionCode: 'cp-hotel-memberCouponUse',
      // 1: 平台 2: 集团 3: 酒店
      permissionCode: 'cp-cumulativelist',
      routerType: 2,
    },
    component: () => import('../views/cumulativehome/cumulative/cumulative.vue'),
  },
  {
    // 丽程分累计项目-编辑
    path: '/pmsone/cumulativeedit',
    name: 'pmsone_cumulativeedit',
    meta: {
      // 可选
      // permissionCode: 'cp-hotel-memberCouponUse',
      // 1: 平台 2: 集团 3: 酒店
      permissionCode: 'cp-cumulativelist',
      routerType: 2,
    },
    component: () => import('../views/cumulativehome/editproject/editproject.vue'),
  },
  {
    // 丽程分累计项目-操作日志
    path: '/pmsone/cumulativelogs',
    name: 'pmsone_cumulativelogs',
    meta: {
      // 可选
      // permissionCode: 'cp-hotel-memberCouponUse',
      // 1: 平台 2: 集团 3: 酒店
      permissionCode: 'cp-cumulativelist',
      routerType: 2,
    },
    component: () => import('../views/cumulativehome/logs/logs.vue'),
  },
  // --------丽程分兑换----------- //
  {
    // 丽程分兑换规则-列表
    path: '/pmsone/rulelist',
    name: 'pmsone_rulelist',
    meta: {
      // 可选
      // permissionCode: 'cp-hotel-memberCouponUse',
      // 1: 平台 2: 集团 3: 酒店
      permissionCode: 'cp-cumulativelist',
      routerType: 2,
    },
    component: () => import('../views/rulehome/rulehome/rulehome.vue'),
  },
  {
    // 丽程分兑换规则-添加/编辑
    path: '/pmsone/ruleedit',
    name: 'pmsone_ruleedit',
    meta: {
      // 可选
      // permissionCode: 'cp-hotel-memberCouponUse',
      // 1: 平台 2: 集团 3: 酒店
      permissionCode: 'cp-cumulativelist',
      routerType: 2,
    },
    component: () => import('../views/rulehome/editrule/editrule.vue'),
  },
  // -----待办事项---//
  {
    // 丽程分-待办
    path: '/pmsone/todothing',
    name: 'pmsone_todothing',
    meta: {
      // 可选
      // permissionCode: 'cp-hotel-memberCouponUse',
      // 1: 平台 2: 集团 3: 酒店
      permissionCode: 'cp-cumulativelist',
      routerType: 2,
    },
    component: () => import('../views/todothing/todohome/todohome.vue'),
  },
  {
    // 丽程分-待办-编辑
    path: '/pmsone/todoedit',
    name: 'pmsone_todoedit',
    meta: {
      // 可选
      // permissionCode: 'cp-hotel-memberCouponUse',
      // 1: 平台 2: 集团 3: 酒店
      permissionCode: 'cp-cumulativelist',
      routerType: 2,
    },
    component: () => import('../views/todothing/todoedit/todoedit.vue'),
  },

];
